import React from 'react'
import '../../components/styles/global.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Downloadable from '../../components/common/Downloadable'
import Layout from '../../components/common/Layout'

export default function Interferon() {
    return (
        <Layout active='research-ifns-use-in-mpns'
        title='PharmaEssentia Medical Affairs | Areas of Research'
        description='Educational information relating to PharmaEssentia’s therapeutic areas of interest, including polycythemia vera, essential thrombocythemia, and interferon use in myeloproliferative neoplasms.'
        socialTitle='PharmaEssentia Medical Affairs | Areas of Research'
        socialDescription='Educational information relating to PharmaEssentia’s therapeutic areas of interest, including polycythemia vera, essential thrombocythemia, and interferon use in myeloproliferative neoplasms.'
        pathname='/research'
        >
            <section>
                <div className='inner'>
                    <h1>Areas of Research</h1>
                    <p>This section contains educational information relating to PharmaEssentia’s therapeutic areas of interest.</p>
                    <SegmentedControl buttons='areas-of-research' active='research-ifns-use-in-mpns'/>
                    <h3>Interferon Use in Myeloproliferative Neoplasms (MPNs)</h3>
                    
                    <picture>
                        <source media="(max-width: 799px)" srcSet="/svg/ifn-use-in-mpns-mobile.svg" />
                        <source media="(min-width: 800px)" srcSet="/svg/ifn-use-in-mpns.svg" />
                        <img src="/svg/ifn-use-in-mpns.svg" alt="ifn use in mpns"></img>
                    </picture>

                    {/* <span className='divider'></span>    */}
                    <Downloadable
                        title='Role of interferons'
                        description='Interferons can modulate a broad range of biological activities through activation of the JAK/STAT pathway. Through this activation, they can help enhance antitumor responses, cell differentiation, induction of apoptosis, and depletion/regulation of HSPCs. These mechanisms may have clinical relevance in MPNs.'
                        thumbnail='mpn-deck.png'
                        thumbnailAlt='Understanding myeloproliferative neoplasms deck'
                        fileURL='/downloads/understanding-myeloproliferative-neoplasms-mpns.pdf'
                    />           
                </div>
            </section>
        </Layout>
    )
}